import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import {window} from "browser-monads"
import Img from "gatsby-image"

import SEO from "../components/seo"
import BlockContent from '../components/block-content'

import arrow from '../images/agba-arrow.svg'

const AboutPage = ({ data }) => {
  const
    {title, imageFront, imageBack, _rawColumnOne } = data.sanityAbout

    useEffect(() => {
      window.onscroll = (ev) => {
        const
          backImage = document.querySelector('.about--back-image'),
          titleContainer = document.querySelector('.about--title-container'),
          fixedContainer = document.querySelector('.about--fixed-container');

        if(!!backImage) {
          backImage.style.transform = `scale(${1 + (window.scrollY || window.pageYOffset) / 10500})`
        }
        if(!!titleContainer) {
          titleContainer.style.opacity = 1 - (window.scrollY || window.pageYOffset) / 50
        }

        if(!!fixedContainer && (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
          fixedContainer.style.height = "99.9vh"
        } else if(!!fixedContainer && (window.innerHeight + window.pageYOffset) <= document.body.offsetHeight - 2) {
          fixedContainer.style.height = "100vh"
        }
      }
    })

  return (
    <React.Fragment>
      <SEO title="About" />
      <div id="about">

        <div className="about--fixed-container">
            <Img
              fluid={imageBack.asset.fluid}
              className="about--back-image"
              backgroundColor="#f2f2f2"
            />
        </div>

        <div className="about--title-container">
          <h1 data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "1.2s", "--sal-delay": "750ms"}}>{title}</h1>
          <div>
            <img className="about-arrow" src={arrow} alt="scroll down"/>
          </div>
        </div>

        <div className="about--notfixed-container" data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="800">
          <BlockContent classes="about--text-container" id="raw-columnOne" blocks={_rawColumnOne || []}/>
          <div className="about--front-image">
            <img className="front-image" src={imageFront.asset.fluid.src} alt="Ana Georgina Ampudia AGBA Weddings"/>
          </div>
        </div>

        <div className="about--container-mobile" data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
          <h1>{title}</h1>
          <BlockContent classes="about--text-container-mobile" blocks={_rawColumnOne || []} />
        </div>

        <div className="about--link-container">
          <Link className="about--link" to="/contact/">Contact us</Link>
        </div>

      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  {
    sanityAbout {
      title
      imageFront {
        asset {
          fluid(maxWidth: 768) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      imageBack {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawColumnOne
    }
  }
`

export default AboutPage
